import { FormlyFieldConfig } from '@ngx-formly/core';
import { isArray, isFunction } from 'lodash';
import { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { FormData } from '../../../funding-project/funding-project-details/tabs/forms/interfaces/form-data';
import { FormToPdfGenerator } from './form-to-pdf-generator';
import { FormExplanationAttachmentFieldGroupFormatter } from './field-group-formatter/form-explanation-attachment-group-formatter';
import { User } from '../../../core/interfaces/user';
import { FieldFormatter } from './field-formatter/field-formatter';
import { RegistrationNumberFieldFormatter } from './field-formatter/registration-number-field-formatter';
import { GenderFieldFormatter } from './field-formatter/gender-field-formatter';
import { FieldGroupFormatter } from './field-group-formatter/field-group-formatter';
import { MassnahmeNummerFieldGroupFormatter } from './field-group-formatter/massnahme-nummer-field-group-formatter';
import { ProjectActionData } from '../../../funding-project/funding-project-details/interfaces/project-action';
import { ProjectActionService } from '../../../funding-project/funding-project-details/services/project-action.service';
import { FundingProject } from '../../public-api';

/**
 * Enumeration of possible actions on the pdf transformation for a form.
 */
export enum FormOutputAction {
  OPEN,
  PRINT,
  DOWNLOAD,
  BASE64,
  BLOB,
  BUFFER,
  DATAURL,
  STREAM
}

const FORBIDDEN_PDF_CHARACTER = 'ẞ';

/**
 * Class for generation pdf of oaman forms.
 */
export class OAManFormToPdfGenerator {
  // Default document name on pdf download
  private static DEFAULT_DOKUMENT_NAME = 'OAMan-Antrag';

  /**
   * Execution of pdf transformation outside a formly form view
   * with a FormData objects array containing template and model data
   * directly from database.
   *
   * @param formData the FormData objects array containing template and model data
   * @param currentUser the current User object
   * @param projectActionData the project action data
   * @param projectActionService the project action service
   * @param callback the callback function for asynchron formOutputAction types BASE64, BLOB, BUFFER, DATAURL
   * @param fundingProject the fundingProject object containing data for the cover page
   */
  public static executeAction(
    formData: FormData | FormData[],
    currentUser: User | undefined,
    projectActionData: ProjectActionData,
    projectActionService: ProjectActionService,
    callback: (
      projectActionData: ProjectActionData,
      projectActionService: ProjectActionService,
      data: string | Blob | Buffer | PDFKit.PDFDocument
    ) => any,
    fundingProject: any
  ) {
    if (isArray(formData)) {
      formData.forEach((data) => {
        this.executeByFormData(
          data,
          currentUser,
          projectActionData,
          projectActionService,
          callback,
          fundingProject
        );
      });
    } else if (formData) {
      this.executeByFormData(
        formData,
        currentUser,
        projectActionData,
        projectActionService,
        callback,
        fundingProject
      );
    }
  }

  /**
   * Execution of pdf transformation outside a formly form view
   * with one FormData object containing template and model data
   * directly from database.
   *
   * @param formData the form data object containing template and model data
   * @param currentUser the current User object
   * @param projectActionData the project action data
   * @param projectActionService the project action service
   * @param callback the callback function for asynchron formOutputAction types BASE64, BLOB, BUFFER, DATAURL
   * @param fundingProject the fundingProject object containing data for the cover page
   */
  private static executeByFormData(
    formData: FormData,
    currentUser: User | undefined,
    projectActionData: ProjectActionData,
    projectActionService: ProjectActionService,
    callback: (
      projectActionData: ProjectActionData,
      projectActionService: ProjectActionService,
      data: string | Blob | Buffer | PDFKit.PDFDocument
    ) => any,
    fundingProject: any
  ) {
    if (formData?.jsonFormTemplate && formData.jsonData) {
      this.execute(
        JSON.parse(this.escapeForbiddenCharacters(formData.jsonFormTemplate)),
        JSON.parse(this.escapeForbiddenCharacters(formData.jsonData)),
        currentUser,
        projectActionData,
        projectActionService,
        callback,
        fundingProject
      );
    }
  }

  public static escapeForbiddenPDFCharacters(formData: FormData): FormData {
    if (!formData) {
      return formData;
    }

    return JSON.parse(this.escapeForbiddenCharacters(JSON.stringify(formData)));
  }

  private static escapeForbiddenCharacters(stringToVerify: string): string {
    // regexp to replace all occurrences of the 'ẞ' character
    return stringToVerify.replace(
      new RegExp(FORBIDDEN_PDF_CHARACTER, 'g'),
      'ß'
    );
  }

  /**
   * Execution of pdf transformation of a formly form
   * with the related template and model data.
   *
   * @param fieldGroup the form template
   * @param formModel the form model
   * @param currentUser the current User object
   * @param projectActionData the project action data
   * @param projectActionService the project action service
   * @param callback the callback function for asynchron formOutputAction types BASE64, BLOB, BUFFER, DATAURL
   * @param fundingProject the fundingProject object containing the data for the cover page
   */
  public static async execute(
    fieldGroup: FormlyFieldConfig[],
    formModel: any,
    currentUser: User | undefined,
    projectActionData: ProjectActionData,
    projectActionService: ProjectActionService,
    callback: (
      projectActionData: ProjectActionData,
      projectActionService: ProjectActionService,
      data: string | Blob | Buffer | PDFKit.PDFDocument
    ) => any,
    fundingProject: FundingProject
  ) {
    if (fieldGroup && formModel) {
      const pdf = this.generatePdf(
        fieldGroup,
        formModel,
        currentUser,
        projectActionData.title,
        fundingProject
      );
      const formOutputAction = projectActionData.formOutputAction;
      const title = projectActionData.title
        ? projectActionData.title
        : OAManFormToPdfGenerator.DEFAULT_DOKUMENT_NAME;
      const fileName = title + '.pdf';
      const formData = new FormData();
      formData.append('title', title);
      projectActionData.fileUploadData = formData;
      switch (formOutputAction) {
        case FormOutputAction.OPEN:
          pdf.open();
          break;
        case FormOutputAction.PRINT:
          pdf.print();
          break;
        case FormOutputAction.DOWNLOAD:
          pdf.download(title);
          break;
        case FormOutputAction.BASE64:
          if (isFunction(callback)) {
            pdf.getBase64((data: string) => {
              const blobData = new Blob([data]);
              projectActionData.fileUploadData?.append(
                'file',
                blobData,
                fileName
              );
              callback(projectActionData, projectActionService, data);
            });
          } else {
            console.warn('Callback function not defined!');
          }
          break;
        case FormOutputAction.BLOB:
          if (isFunction(callback)) {
            pdf.getBlob((data: Blob) => {
              projectActionData.fileUploadData?.append('file', data, fileName);
              callback(projectActionData, projectActionService, data);
            });
          } else {
            console.warn('Callback function not defined!');
          }
          break;
        case FormOutputAction.BUFFER:
          if (isFunction(callback)) {
            pdf.getBuffer((data: Buffer) => {
              projectActionData.fileUploadData?.append(
                'file',
                new Blob([data]),
                fileName
              );
              callback(projectActionData, projectActionService, data);
            });
          } else {
            console.warn('Callback function not defined!');
          }
          break;
        case FormOutputAction.DATAURL:
          if (isFunction(callback)) {
            pdf.getDataUrl((data: string) => {
              //projectActionData.fileUploadData?.append('file', ???, fileName);
              callback(projectActionData, projectActionService, data);
            });
          } else {
            console.warn('Callback function not defined!');
          }
          break;
        case FormOutputAction.STREAM:
          if (isFunction(callback)) {
            const data: PDFKit.PDFDocument = pdf.getStream();
            //projectActionData.fileUploadData?.append('file', ???, fileName);
            callback(projectActionData, projectActionService, data);
          } else {
            console.warn('Callback function not defined!');
          }
          break;
        default:
          console.warn(
            'FormOutputAction " ' + formOutputAction + ' " not implemented!'
          );
          break;
      }
    }
  }

  /**
   * Creation of the PDFMake TCreatedPdf object based on the
   * related template and model data.
   *
   * @param fieldGroup the form template
   * @param formModel the form model
   * @param currentUser the current User object
   * @param formTitle the title of the form
   * @returns the PDFMake TCreatedPdf object
   */
  public static generatePdf(
    fieldGroup: FormlyFieldConfig[],
    formModel: any,
    currentUser: User | undefined,
    formTitle: string | undefined,
    fundingProject: any
  ): TCreatedPdf {
    const pdfGenerator: FormToPdfGenerator = new FormToPdfGenerator(
      fieldGroup,
      formModel,
      this.defineFieldFormatters(),
      this.defineFieldGroupFormatters(),
      currentUser,
      formTitle,
      fundingProject
    );
    return pdfGenerator.generatePdf();
  }

  /**
   * The default callback function for storing the genearated pdf data.
   *
   * @param projectActionData the project action data
   * @param projectActionService the project action service
   * @param data the pdf data as BASE64, BLOB, BUFFER or DATAURL
   */
  public static saveFormDocument(
    projectActionData: ProjectActionData,
    projectActionService: ProjectActionService,
    data: string | Blob | Buffer | PDFKit.PDFDocument
  ) {
    switch (projectActionData.formOutputAction) {
      case FormOutputAction.BLOB:
      case FormOutputAction.BASE64:
      case FormOutputAction.BUFFER:
        projectActionService.setAction('saveFormDocument', projectActionData);
        break;
      case FormOutputAction.DATAURL:
      case FormOutputAction.STREAM:
      default:
        console.warn(
          'storePdfData() -> formOutputAction " ' +
            projectActionData.formOutputAction +
            ' " not implemented! data: ',
          data
        );
        break;
    }
  }

  /**
   * Definition of the OAMan specific form field formatters.
   *
   * @returns FieldFormatter[] defined field formattes
   */
  private static defineFieldFormatters(): FieldFormatter[] {
    // Define Field formatter
    const regNumberFormatter = new RegistrationNumberFieldFormatter(
      new Array('baseData.registrationNumber')
    );
    const genderFieldFormatter = new GenderFieldFormatter(
      new Array('companyForm.companyForm_G60000139.gender_F60000332')
    );
    return [regNumberFormatter, genderFieldFormatter];
  }

  /**
   * Definition of the OAMan specific form field group formatters.
   *
   * @returns FieldGroupFormatter[] defined field group formatters
   */
  private static defineFieldGroupFormatters(): FieldGroupFormatter[] {
    const massnahmeNummerFieldGroupFormatter =
      new MassnahmeNummerFieldGroupFormatter(
        new Array('project.requestedIndividualProject.measureNr')
      );

    const formExplanationAttachmentFieldGroupFormatter =
      new FormExplanationAttachmentFieldGroupFormatter(
        new Array('explanations')
      );

    return [
      massnahmeNummerFieldGroupFormatter,
      formExplanationAttachmentFieldGroupFormatter
    ];
  }
}
