<mat-toolbar id="main-toolbar" class="mat-toolbar-row mat-toolbar-single-row"
             fxLayout="row"
             [ngClass]="{'authorized': (authorized$ | async), 'not-authorized': (authorized$ | async) === false}">
  <button mat-button routerLink="/home" fxFlexAlign="start" style="width: fit-content; height: fit-content">
    <img class="card-image" alt="OaMan Ärl Logo" id="Oaman-Logo" src="assets/logo_oaman.png"/>
  </button>

  <app-tabs>
  </app-tabs>
  <span class="spacer" id="spacer"></span>
  <app-menu [style.margin-right]="'2vw'"></app-menu>

  <form fxFlexAlign="end center" #logoContainer fxLayout="row">
    <img class="card-image" alt="eu-logo" id="EU-Logo" src="assets/logo-eu.svg" />
    <img class="card-image" alt="niedersachsen Logo" id="Niedersachsen-Logo" src="assets/niedersachsen-klar.jpg"/>
  </form>

</mat-toolbar>

