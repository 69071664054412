import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationQueueService {
  private readonly stack = Array();

  constructor(private readonly router: Router) {}

  /**
   * Adds navigation points to a stack for collecting multiple navigations
   *
   * @param commands An array of URL fragments with which to construct the target URL. See also https://v17.angular.io/api/router/Router#navigate
   * @param extras An options object that determines how the URL should be constructed or interpreted
   */
  addNavigation(commands: any[], extras: NavigationExtras = {}) {
    this.stack.push([commands, extras]);
  }

  /**
   * Navigates to the next navigation point on the stack if there is one
   *
   * @returns A Promise that resolves to true when navigation succeeds, to false when navigation fails or there is no navigation point on the stack
   */
  async executeNextNavigation(): Promise<boolean> {
    if (this.stack.length > 0) {
      const elem = this.stack.pop();
      const commands: any[] = elem[0];
      const extras: NavigationExtras = elem[1];

      return await this.router.navigate(commands, extras);
    } else {
      return false;
    }
  }
}
