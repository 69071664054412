import { TrustLevel, User } from '../interfaces/user';

/**
 * Verifies if the given user is logged in with High trust level.
 * @param user
 * @returns
 */
export function isUserWithHighTrustLevel(user: User): boolean {
  if (user.loginType === 'LDAP') {
    console.warn(
      'LDAP Users considered with HIGH trust level temporarily! Please disable this functionality for PROD.'
    );
    return true;
  }
  return (
    user?.trustLevel === TrustLevel.HIGH ||
    user?.trustLevel === TrustLevel.SUBSTANTIAL
  );
}

export function getLoginTypeLabel(user: User): string {
  const loginType = user.loginType;
  if (loginType === 'SERVICEKONTO') {
    return 'Servicekonto';
  }
  if (loginType === 'UNTERNEHMENSKONTO') {
    return 'Unternehmenskonto';
  }

  return loginType === 'BUNDID' ? 'BundID-Konto' : 'Unbekannte';
}

export function getTrustLevelName(user: User): string {
  switch (user.trustLevel) {
    case TrustLevel.HIGH:
      return 'Hoch';
    case TrustLevel.SUBSTANTIAL:
      return 'Substanziell';
    case TrustLevel.LOW:
      return 'Niedrig';
  }
}
