import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExplanationsFieldObserverService {
  private explanationDataSubject: Subject<boolean> = new Subject<boolean>();
  private auszahlungsantragDataSubject: Subject<boolean> =
    new Subject<boolean>();
  private sachberichtDataSubject: Subject<boolean> = new Subject<boolean>();

  public auszahlungsantragData$: Observable<boolean> =
    this.auszahlungsantragDataSubject.asObservable();
  public explanationData$: Observable<boolean> =
    this.explanationDataSubject.asObservable();
  public sachberichtData$: Observable<boolean> =
    this.sachberichtDataSubject.asObservable();

  constructor() {}

  updateExplanationData(newExplanationData: boolean): void {
    this.explanationDataSubject.next(newExplanationData);
  }
  updateAuszahlungsantragData(newAuszahlungsantragData: boolean): void {
    this.auszahlungsantragDataSubject.next(newAuszahlungsantragData);
  }
  updateSachberichtData(newSachberichtData: boolean): void {
    this.sachberichtDataSubject.next(newSachberichtData);
  }
}
