<h2 mat-dialog-title>
  {{
    'fundingProject.details.documents.dialog.provideDocument.docTitle'
      | translate
  }}
</h2>

<form
  [formGroup]="documentForm"
  class="document-form"
  (ngSubmit)="onSubmit()"
  novalidate
>
  <mat-dialog-content class="mat-typography">
    <p class="document-desc-1">
      {{
        'fundingProject.details.documents.dialog.provideDocument.message1'
          | translate
      }}
    </p>
    <p>
      {{
        'fundingProject.details.documents.dialog.provideDocument.message2'
          | translate
      }}
    </p>

    <label for="document-title">
      {{
        'fundingProject.details.documents.dialog.provideDocument.titleLabel'
          | translate
      }}
      <span [style.color]="'red'"> *</span>
    </label>
    <input
      id="document-title"
      formControlName="documentTitle"
      [style.width]="'100%'"
      [style.height]="'2rem'"
      [style.box-sizing]="'border-box'"
      type="text"
    />
    <ng-container *ngIf="documentForm.controls['documentTitle'].invalid &&
    (documentForm.get('documentTitle')?.dirty ||
      documentForm.get('documentTitle')?.touched)">
      <div class="validation-msg">
        <div *ngIf="documentForm.get('documentTitle')?.errors?.required">
          {{ 'validationErrorMessages.required' | translate }}
        </div>
        <div *ngIf="documentForm.get('documentTitle')?.errors?.minlength">
          {{ 'validationErrorMessages.minLength' | translate }}
        </div>
        <div *ngIf="documentForm.get('documentTitle')?.errors?.maxlength">
          {{ 'validationErrorMessages.maxLength' | translate: { lengthVar: maxDocTitleAndFileNameLength } }}
        </div>
        <div *ngIf="documentForm.get('documentTitle')?.errors?.pattern">
          {{ 'validationErrorMessages.forbiddenCharacters' | translate: { forbiddenChars: forbiddenSpecialChars } }}
        </div>
      </div>
    </ng-container>

    <mat-form-field appearance="fill" class="full-width document-field">
      <mat-label>
        {{
          'fundingProject.details.documents.dialog.provideDocument.documentType'
            | translate
        }}
      </mat-label>
      <mat-select
        formControlName="documentType"
        [(value)]="selectedDocTypeId"
        (selectionChange)="onDocTypeChange()"
        [title]="
          'fundingProject.details.documents.dialog.provideDocument.documentTypeTooltip'
            | translate
        "
      >
        <mat-option *ngFor="let docType of availableDocumentTypes" [value]="docType.id">
          {{ docType.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="full-width document-field"
      *ngIf="isNoticeDocumentType()"
    >
      <mat-label>
        {{
          'fundingProject.details.documents.dialog.provideDocument.docSubType'
            | translate
        }}
      </mat-label>
      <mat-select
        formControlName="documentSubType"
        [(value)]="selectedDocSubTypeId"
        [title]="
          'fundingProject.details.documents.dialog.provideDocument.docSubTypeTooltip'
            | translate
        "
      >
        <mat-option
          *ngFor="let documentSubType of documentSubTypes"
          [value]="documentSubType.id"
        >
          {{ documentSubType.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{
        'fundingProject.details.documents.dialog.provideDocument.relatedRequest'
          | translate
      }}</mat-label>
      <mat-select
        formControlName="documentRequest"
        [(value)]="selectedRequestId"
        (selectionChange)="onRequestChange()"
        [title]="
          'fundingProject.details.documents.dialog.provideDocument.relatedRequestTooltip'
            | translate
        "
      >
        <mat-option
          *ngFor="let request of availableRequests"
          [value]="request.id"
        >
          {{ 'fundingProject.' + request.type | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <p>
      {{ 'fundingProject.details.documents.dialog.provideDocument.acceptFileFormat' | translate }}
    </p>

    <div
      fxLayout="row wrap"
      fxLayout.lt-sm="column wrap"
      fxLayoutGap="0.2rem"
      fxLayoutAlign="start center"
    >
      <button
        mat-raised-button
        color="primary"
        class="mb-3"
        type="button"
        [disabled]="maxFileNumberReached"
        (click)="fileInput.click()"
      >
        {{
          'fundingProject.details.documents.dialog.buttons.uploadBtn'
            | translate
        }}
        <input
          #fileInput
          multiple
          type="file"
          class="file-upload"
          [accept]="requiredFileType"
          (change)="onFileSelected($event)"
        />
      </button>
    </div>
    <p class="margin-10" *ngIf="documentFiles.length === 0">
      {{
        'fundingProject.details.documents.dialog.provideDocument.noFiles'
          | translate
      }}
    </p>
    <p>
      {{
      'fundingProject.details.documents.dialog.provideDocument.limitedFileSize'
        | translate:{ maxLimit: maxFileSizeMb }
      }}
    </p>

    <div
      class="margin-10"
      fxLayout="row wrap"
      fxLayout.lt-sm="column wrap"
      fxLayoutGap="0.2rem"
      fxLayoutAlign="start center"
      *ngFor="let docFile of documentFiles"
    >
      <div
        fxFlex="85%"
        [ngClass]="{
          'file-size-exceeded': docFile.limitExceeded || docFile.fileNameMaxLengthExceeded || docFile.invalidFileName,
          'delivery-size-exceeded':
            docFile.deliveryLimitExceeded && !docFile.limitExceeded
        }"
      >
        {{ docFile.fileName }}
      </div>
      <div class="file-exceeded-icon">
        <mat-icon
          *ngIf="docFile.limitExceeded"
          matTooltipClass="tooltip"
          matTooltip="{{
            'fundingProject.details.documents.dialog.provideDocument.fileSizeExceeded'
              | translate: { maxLimit: maxFileSizeMb }
          }}"
          >info</mat-icon
        >
        <mat-icon
          *ngIf="docFile.deliveryLimitExceeded && !docFile.limitExceeded"
          matTooltipClass="tooltip"
          matTooltip="{{
            'fundingProject.details.documents.dialog.provideDocument.deliveryFileSizeExceeded'
              | translate: { maxLimit: maxDeliveryFileSizeMb }
          }}"
          >info</mat-icon
        >
        <mat-icon
          *ngIf="docFile.invalidFileName && !docFile.fileNameMaxLengthExceeded"
          matTooltipClass="tooltip"
          matTooltip="{{ 'fundingProject.details.documents.dialog.uploadFile.forbiddenCharsInDocumentTitle' | translate: {forbiddenChars: forbiddenCharsInFileName } }}"
        >info</mat-icon
        >
        <mat-icon
          *ngIf="docFile.fileNameMaxLengthExceeded && !docFile.invalidFileName"
          matTooltipClass="tooltip"
          matTooltip="{{ 'fundingProject.details.documents.dialog.uploadFile.maxFileNameLength' | translate: { lengthVar: maxDocTitleAndFileNameLength} }}"
        >info</mat-icon>
        <mat-icon
          *ngIf="docFile.fileNameMaxLengthExceeded && docFile.invalidFileName"
          matTooltipClass="tooltip"
          matTooltip="{{ 'fundingProject.details.documents.dialog.uploadFile.maxFileNameAndForbiddenChars' | translate: { lengthVar: maxDocTitleAndFileNameLength, forbiddenChars: forbiddenCharsInFileName} }}"
          style="white-space: pre-line"
        >info</mat-icon>
      </div>
      <div>
        <mat-icon (click)="onFileRemove(docFile)">cancel</mat-icon>
      </div>
    </div>

    <p *ngIf="maxFileNumberReached" class="max-file-number">
      {{
        'fundingProject.details.documents.dialog.provideDocument.maxFileNumberReached'
          | translate: { maxLimit: maxFileNumber }
      }}
    </p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <oaman-button
      [mat-dialog-close]="false"
      [style.margin-right]="'1rem'"
      type="reset"
    >
      {{
        'fundingProject.details.documents.dialog.buttons.cancelBtn' | translate
      }}
    </oaman-button>
    <oaman-button
      color="primary"
      [disabled]="!isSaveActive() || !documentForm.valid"
      type="submit"
    >
      {{
        'fundingProject.details.documents.dialog.provideDocument.submit'
          | translate
      }}
    </oaman-button>
  </mat-dialog-actions>

  <mat-progress-bar
    mode="determinate"
    *ngIf="activeProgressBar"
    value="20"
    class="full-width"
  ></mat-progress-bar>
</form>
