export const FUNDING_MEASURE_FLURBEREINIGUNG = 'Flurbereinigung';
export const FUNDING_MEASURE_DORFENTWICKLUNG = 'Dorfentwicklung';
export const FUNDING_MEASURE_DORFENTWICKLUNGSPLAN = 'Dorfentwicklungsplan';
export const FUNDING_MEASURE_BASISDIENSTLEISTUNGEN = 'Basisdienstleistungen';
export const FUNDING_MEASURE_KLEINSTUNTERNEHMEN =
  'Kleinstunternehmen der Grundversorgung';

export const LEGAL_FORM_SONSTIGE_GEBIETKORPERSCHAFT =
  'Sonstige Gebietskörperschaft';
export const LEGAL_FORM_KORPERSCHAFT_OFFENTLICHES_RECHT =
  'Körperschaften des öffentlichen Rechts';

export const LEGAL_FORM_EHELEUTE = 'Eheleute (soweit keine GbR)';
export const LEGAL_FORM_SONSTIGE_PERSONENGESELLSCHAFTEN =
  'Sonstige Personengesellschaften (z. B. Erbengemeinschaften)';
export const LEGAL_FORM_GESELLSCHAFT_DES_BUERGERLICHEN_RECHTS =
  'Gesellschaft des bürgerlichen Rechts (GbR)';

export const FORM_TEMPLATE_ZILE = 'ZILE - Investive Maßnahmen';
export const FORM_TEMPLATE_VEREINE = 'Einlageblatt Verein';
export const FORM_TEMPLATE_TEILNEHMER = 'Einlageblatt Flurbereinigung';
export const FORM_TEMPLATE_LEADER = 'Erstantrag LEADER';
export const FORM_TEMPLATE_LEADER_VERWENDUNGSNACHWEIS =
  'LEADER - Verwendungsnachweis';
export const FORM_TEMPLATE_ZILE_VERWENDUNGSNACHWEIS =
  'ZILE - Verwendungsnachweis';
export const REQUEST_STATE_CREATED = 'request.state.created';
export const FINANCIAL_PLAN_REVENUE_TO_FINANCE_EXPENDITURES =
  'financialPlan.revenueToFinanceExpenditures';
export const FINANCIAL_PLAN_EXPENDITURES = 'financialPlan.expenditures';
export const ANGABE_ZUR_FINANZIERUNG = 'Angaben zur Finanzierung';
export const STYLE_WITH_RED_BORDERS =
  'width: 100%; box-sizing: border-box; text-align: right; border-color: red!important;';
export const STYLE_WITHOUT_RED_BORDERS =
  'width: 100%; box-sizing: border-box; text-align: right;';
export const INPUT = 'Input';
export const TOTAL_COSTS_REQUESTED = 'totalCostsRequested';
export const TOTAL_COSTS_REVENUE_TFE = 'totalCostsRevenueToFinanceExpenditures';
export const TOTAL_COSTS_REQUESTED_INPUT_FIELD_ERROR =
  'Die Kosten insgesamt weichen von den Gesamteinnahmen ab!';
export const TOTAL_COSTS_REVENUE_TFE_INPUT_FIELD_ERROR =
  'Die Gesamteinnahmen weichen von den Kosten insgesamt ab!';
export const EXPLANATIONS = 'explanations';
export const ERKLAERUNGEN_UND_ANLAGEN = 'Erklärungen und Anlagen';
export const AUSZAHLUNGSANTRAG = 'Auszahlungsantrag';
